// THIS IS THE ORIGIANL CODE FOR THE BLOG SECTION 

import React from "react";
import { Link } from 'gatsby';

import Paper from '@material-ui/core/Paper';

import { graphql, useStaticQuery, StaticQuery} from 'gatsby';

import withStyles from "@material-ui/core/styles/withStyles";

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import workStyle from "assets/jss/material-kit-react/views/landingPageSections/workStyle.jsx";

class BlogSectionOriginal extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.section} >
        <GridContainer justify="center" className={classes.description}>
          <GridItem cs={12} sm={12} md={8}>
          <StaticQuery 
                query={graphql`
                    query {
                        allMarkdownRemark{
                            edges{
                                node{
                                    frontmatter{
                                        title
                                        date
                                    }
                                    fields{
                                        slug
                                    }
                                }
                            }
                        }
                    }
                `}
                render={data => (
                    <div>
                            {data.allMarkdownRemark.edges.map((edge) => {
                                return(
                                    <Paper>
                                        <Link to={`/blog-page/${edge.node.fields.slug}`}>
                                            <h2>{edge.node.frontmatter.title}</h2>
                                        </Link>
                                        
                                        <p>{edge.node.frontmatter.date}</p>
                                    </Paper>
                                )
                            })}
                    </div>
                )}
            />
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

export default withStyles(workStyle)(BlogSectionOriginal);

